exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-businessconnectivity-jsx": () => import("./../../../src/pages/businessconnectivity.jsx" /* webpackChunkName: "component---src-pages-businessconnectivity-jsx" */),
  "component---src-pages-chatterbox-matrix-jsx": () => import("./../../../src/pages/chatterbox-matrix.jsx" /* webpackChunkName: "component---src-pages-chatterbox-matrix-jsx" */),
  "component---src-pages-connected-countries-jsx": () => import("./../../../src/pages/connected-countries.jsx" /* webpackChunkName: "component---src-pages-connected-countries-jsx" */),
  "component---src-pages-de-404-tsx": () => import("./../../../src/pages/de/404.tsx" /* webpackChunkName: "component---src-pages-de-404-tsx" */),
  "component---src-pages-nl-404-tsx": () => import("./../../../src/pages/nl/404.tsx" /* webpackChunkName: "component---src-pages-nl-404-tsx" */),
  "component---src-pages-nomadindex-jsx": () => import("./../../../src/pages/nomadindex.jsx" /* webpackChunkName: "component---src-pages-nomadindex-jsx" */),
  "component---src-pages-partner-ekm-jsx": () => import("./../../../src/pages/partner/ekm.jsx" /* webpackChunkName: "component---src-pages-partner-ekm-jsx" */),
  "component---src-pages-recession-proof-index-jsx": () => import("./../../../src/pages/recession-proof-index.jsx" /* webpackChunkName: "component---src-pages-recession-proof-index-jsx" */),
  "component---src-pages-release-notes-tsx": () => import("./../../../src/pages/release-notes.tsx" /* webpackChunkName: "component---src-pages-release-notes-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-partner-tsx": () => import("./../../../src/templates/Partner.tsx" /* webpackChunkName: "component---src-templates-partner-tsx" */)
}

